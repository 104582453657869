import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Menu, Button, Drawer, Typography } from "antd";
import { InboxOutlined, FireOutlined, MenuOutlined, HomeOutlined, FileSearchOutlined, FileDoneOutlined, ContactsOutlined, UserOutlined, LogoutOutlined, CloseOutlined } from '@ant-design/icons';
import Auth from "../../utils/auth";
import logo from '../../assets/logo.png';
import { FeatureFlagsContext } from '../../contexts/FeatureFlagsContext';

const { Item } = Menu;
const { Title } = Typography;

const menuStyle = { color: '#2ca4cc', fontWeight: 'bold' };

function MobilePageNav() {
    const featureFlags = useContext(FeatureFlagsContext);
    const [openGuide, setOpenGuide] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const handleLogout = () => {
        Auth.logout();
    };

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px', backgroundColor: '#001529', borderBottom: '1px solid #ddd' }}>
            <Link to="/" onClick={(event) => {
                event.preventDefault();
                window.location.href = '/';
            }}>
                <img src={logo} alt="Logo" style={{ height: '40px', marginLeft: '2.5px' }} />
            </Link>
            <MenuOutlined onClick={showDrawer} style={{ color: '#2ca4cc', fontSize: '20px', marginRight: '7.5px' }} />
            <Drawer
                placement="top"
                closable={true}
                onClose={closeDrawer}
                theme="dark"
                visible={drawerVisible}
                drawerStyle={{ backgroundColor: '#001529' }}
                closeIcon={<CloseOutlined style={{ color: '#2ca4cc' }} />}
            >
                <Menu
                    mode="vertical"
                    theme="dark"
                    style={menuStyle}
                >
                    <Item key="home" icon={<HomeOutlined />} style={menuStyle}>
                        <Link to="/" onClick={closeDrawer}>Home</Link>
                    </Item>
                    {featureFlags.pageNav.opportunities && (
                        <Item key="matcher" icon={<FireOutlined />} style={menuStyle}>
                            <Link to="/matcher" onClick={closeDrawer}>Matcher</Link>
                        </Item>
                    )}
                    {featureFlags.pageNav.contacts && (
                        <Item key="contacts" icon={<ContactsOutlined />}style={menuStyle}>
                            <Link to="/contacts" onClick={closeDrawer}>Contacts</Link>
                        </Item>
                    )}
                    {featureFlags.pageNav.contacts && (
                        <Item key="inbox" icon={<InboxOutlined />} style={menuStyle}>
                            <Link to="/inbox" onClick={closeDrawer}>Inbox</Link>
                        </Item>
                    )}
                    <Item key="profile" icon={<UserOutlined />}>
                        <Link to="/profile" onClick={closeDrawer}>Profile</Link>
                    </Item>
                    <Item key="logout" icon={<LogoutOutlined />} onClick={() => { handleLogout(); closeDrawer(); }} style={menuStyle}>
                        Logout
                    </Item>
                </Menu>
            </Drawer>
        </div>
    );
}

export default MobilePageNav;
